<template>
  <div
    id="modalInceresLogin"
    class="modal fade"
    tabindex="-1"
    data-keyboard="false"
    data-backdrop="static"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Login no Inceres</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            title="Fechar"
            @click="$emit('closeModal', false)"
          >
            &times;
          </button>
        </div>
        <form @submit.prevent="login">
          <div class="modal-body">
            <div class="form-row">
              <div class="form-group col-sm-6">
                <label>E-mail: </label>
                <input
                  type="email"
                  class="form-control"
                  :class="{ 'is-invalid': $v.credetials.email.$error }"
                  v-model="$v.credetials.email.$model"
                  placeholder="usuario@email.com.br"
                />
                <span
                  v-if="!$v.credetials.email.required"
                  class="invalid-feedback"
                >
                  E-mail é obrigatório
                </span>
                <span
                  v-if="!$v.credetials.email.email"
                  class="invalid-feedback"
                >
                  Formato de e-mail inválido
                </span>
              </div>
              <div class="form-group col-sm-6">
                <label>Senha: </label>
                <input
                  type="password"
                  class="form-control"
                  :class="{ 'is-invalid': $v.credetials.password.$error }"
                  v-model="$v.credetials.password.$model"
                  placeholder="Senha"
                />
                <span
                  v-if="!$v.credetials.password.required"
                  class="invalid-feedback"
                >
                  Senha é obrigatória
                </span>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-link"
              data-dismiss="modal"
              title="Cancelar"
              @click="$emit('closeModal', false)"
            >
              Cancelar
            </button>
            <button
              type="submit"
              class="btn btn-primary"
              title="Login"
              :disabled="$v.$invalid || disableButton"
            >
              <span v-if="disableButton">
                <i class="icon-spinner2 spinner" />
              </span>
              <span v-else>Login</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'

export default {
  name: 'InceresLoginModal',

  data() {
    return {
      credetials: {
        email: '',
        password: '',
      },
      disableButton: false,
    }
  },

  methods: {
    async login() {
      if (!this.$v.$invalid) {
        this.disableButton = true
        try {
          const response = await this.$store.dispatch(
            'loginInceres',
            this.credetials,
          )
          this.disableButton = false
          window.$('#modalInceresLogin').modal('hide')
          this.$emit('closeModal')
          this.$root.$children[0].$refs.notification.success(response.message)
        } catch (error) {
          this.disableButton = false
          if (error && error.message) {
            if (error.message === 'Not Authorized') {
              this.$root.$children[0].$refs.notification.warning(
                'Usuário ou senha inválidos.',
              )
            } else {
              this.$root.$children[0].$refs.notification.warning(error.message)
            }
          } else {
            this.$root.$children[0].$refs.notification.danger(
              'Não foi possível se conectar ao servidor.',
            )
          }
        }
      }
    },
  },

  validations: {
    credetials: {
      email: {
        email,
        required,
      },
      password: {
        required,
      },
    },
  },

  mounted() {
    window.$('#modalInceresLogin').modal()
  },
}
</script>
