<template>
  <table class="table" :id="dataTable.id">
    <thead>
      <tr>
        <th>Nome</th>
        <th>Cidade/Estado</th>
        <th>Criado por</th>
        <th>Atualizado por</th>
        <th class="text-center">Status</th>
        <th class="text-center">Ações</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="farm in farms" :key="farm.id">
        <td>{{ farm.name }}</td>
        <td>{{ farm.city }}/{{ farm.uf }}</td>
        <td>{{ createdUpdatedUser(farm.createdBy, farm.createdAt) }}</td>
        <td>{{ createdUpdatedUser(farm.updatedBy, farm.updatedAt) }}</td>
        <td class="text-center" v-html="statusTranslate(farm.status)" />
        <td class="text-center">
          <button
            v-if="getModuleUserByCode('1.01.003')"
            type="button"
            class="btn btn-outline-warning btn-icon rounded-pill"
            title="Editar"
            @click="$emit('editFarm', farm.id)"
          >
            <i class="icon-pencil" />
          </button>
          <router-link
            :to="`${farm.clientId}/farm/${farm.id}`"
            class="btn btn-outline-primary btn-icon rounded-pill ml-2"
            title="Detalhes"
          >
            <i class="far fa-file-alt" />
          </router-link>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import 'datatables.net-dt/js/dataTables.dataTables'
import '@/utils/basicDataTableExtend'
import { mapGetters } from 'vuex'
import { createdUpdatedUser, statusTranslate } from '@/utils/strings'

export default {
  name: 'ListFarms',

  props: {
    farms: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      dataTable: {
        id: 'dataTableListFarms',
        columnDefs: [
          {
            orderable: false,
            width: 130,
            targets: [5],
          },
        ],
      },
      createdUpdatedUser,
      statusTranslate,
    }
  },

  computed: {
    ...mapGetters(['getModuleUserByCode']),
  },

  methods: {
    createDataTable() {
      window.$(`#${this.dataTable.id}`).DataTable({
        columnDefs: this.dataTable.columnDefs,
      })
    },
  },

  mounted() {
    this.createDataTable()
  },
}
</script>
