<template>
  <div
    id="modalCreateEditFarm"
    class="modal fade"
    tabindex="-1"
    data-keyboard="false"
    data-backdrop="static"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ textModal }}
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            title="Fechar"
            @click="$emit('closeModal', false)"
          >
            &times;
          </button>
        </div>
        <form @submit.prevent="save">
          <div class="modal-body">
            <div class="form-row">
              <div class="form-group col-sm-12">
                <label>Nome: <span class="text-danger">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': $v.farm.name.$error }"
                  placeholder="Nome da Fazenda"
                  v-model="$v.farm.name.$model"
                />
                <span v-if="!$v.farm.name.required" class="invalid-feedback">
                  O campo é obrigatório
                </span>
                <span v-if="!$v.farm.name.minLength" class="invalid-feedback">
                  O nome deve possuir pelo menos 3 caracteres
                </span>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-6">
                <label>Estado: <span class="text-danger">*</span></label>
                <select
                  class="form-control"
                  :class="{ 'is-invalid': $v.farm.stateId.$error }"
                  v-model="$v.farm.stateId.$model"
                >
                  <option value="">Selecione o estado</option>
                  <option
                    v-for="state in states"
                    :key="state.id"
                    :value="state.id"
                    :title="`${state.uf} - ${state.name}`"
                  >
                    {{ state.uf }}
                  </option>
                </select>
                <span v-if="!$v.farm.stateId.required" class="invalid-feedback">
                  O campo é obrigatório
                </span>
              </div>
              <div class="form-group col-sm-6">
                <label>Cidade: <span class="text-danger">*</span></label>
                <select
                  class="form-control"
                  :class="{ 'is-invalid': $v.farm.cityId.$error }"
                  v-model="$v.farm.cityId.$model"
                >
                  <option value="">Selecione uma cidade</option>
                  <option
                    v-for="city in cities"
                    :key="city.id"
                    :value="city.id"
                    :title="city.name"
                  >
                    {{ city.name }}
                  </option>
                </select>
                <span v-if="!$v.farm.cityId.required" class="invalid-feedback">
                  O campo é obrigatório
                </span>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-6">
                <label>Latitude:</label>
                <input
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': $v.farm.latitude.$error }"
                  placeholder="Latitude"
                  v-model="$v.farm.latitude.$model"
                />
                <span class="form-text text-muted">
                  Ex: +90.0 | 45 | -90 | -90.000 | 47.123123
                </span>
                <span
                  v-if="!$v.farm.latitude.latitude"
                  class="invalid-feedback"
                >
                  Formato inválido
                </span>
              </div>
              <div class="form-group col-sm-6">
                <label>Longitude:</label>
                <input
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': $v.farm.longitude.$error }"
                  placeholder="Longitude"
                  v-model="$v.farm.longitude.$model"
                />
                <span class="form-text text-muted">
                  Ex: -127.554334 | 180 | -180 | 179.999999
                </span>
                <span
                  v-if="!$v.farm.longitude.longitude"
                  class="invalid-feedback"
                >
                  Formato inválido
                </span>
              </div>
            </div>
            <div v-if="farmId" class="form-row">
              <div class="form-group col-sm-6">
                <p>Status: <span class="text-danger">*</span></p>
                <div class="form-check form-check-inline">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="statusInativo"
                    value="0"
                    v-model="farm.status"
                  />
                  <label class="form-check-label" for="statusInativo">
                    Inativo
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="statusAtivo"
                    value="1"
                    v-model="farm.status"
                  />
                  <label class="form-check-label" for="statusAtivo">
                    Ativo
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-link"
              data-dismiss="modal"
              title="Cancelar"
              @click="$emit('closeModal', false)"
            >
              Cancelar
            </button>
            <button
              type="submit"
              class="btn btn-primary"
              :title="textModal"
              :disabled="$v.$invalid || disableButton"
            >
              <span v-if="disableButton">
                <i class="icon-spinner2 spinner" />
              </span>
              <span v-else>
                {{ textModal }}
              </span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength, helpers } from 'vuelidate/lib/validators'

const latitude = helpers.regex(
  'latitude',
  /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,11})?))$/,
)

const longitude = helpers.regex(
  'longitude',
  /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,11})?))$/,
)

export default {
  name: 'CreateEditFarmModal',

  props: {
    clientId: {
      type: String,
      required: true,
    },
    farmId: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      farm: {
        clientId: this.clientId,
        name: '',
        stateId: '',
        cityId: '',
        latitude: '',
        longitude: '',
      },
      disableButton: false,
      states: [],
      cities: [],
      loadDataEditing: false,
    }
  },

  computed: {
    textModal() {
      return this.farmId ? 'Atualizar' : 'Cadastrar'
    },
  },

  watch: {
    'farm.stateId': {
      handler() {
        if (!this.loadDataEditing) {
          this.farm.cityId = ''
        }
        this.getCities()
      },
    },
  },

  methods: {
    async getStates() {
      try {
        this.states = await this.$store.dispatch('getStates')
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    async getCities() {
      try {
        this.cities = await this.$store.dispatch('getCities', this.farm.stateId)
        this.loadDataEditing = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    async getFarm() {
      try {
        this.loadDataEditing = true
        this.farm = await this.$store.dispatch('getFarm', {
          clientId: this.clientId,
          farmId: this.farmId,
        })
        window.$('#modalCreateEditFarm').modal()
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
        window.$('#modalCreateEditFarm').modal('hide')
        this.$emit('closeModal', false)
      }
    },

    async save() {
      if (!this.$v.$invalid) {
        this.disableButton = true
        try {
          let response = null
          if (this.farmId) {
            response = await this.$store.dispatch('updateFarm', {
              clientId: this.clientId,
              farmId: this.farmId,
              farm: this.farm,
            })
          } else {
            response = await this.$store.dispatch('saveFarm', {
              clientId: this.clientId,
              farm: this.farm,
            })
          }
          this.disableButton = false
          window.$('#modalCreateEditFarm').modal('hide')
          this.$emit('closeModal', true)
          this.$root.$children[0].$refs.notification.success(response.message)
        } catch (error) {
          this.disableButton = false
          if (error && error.message) {
            this.$root.$children[0].$refs.notification.warning(error.message)
          } else {
            this.$root.$children[0].$refs.notification.danger(
              'Não foi possível se conectar ao servidor.',
            )
          }
        }
      }
    },
  },

  validations: {
    farm: {
      name: {
        required,
        minLength: minLength(3),
      },
      stateId: {
        required,
      },
      cityId: {
        required,
      },
      latitude: {
        latitude,
      },
      longitude: {
        longitude,
      },
    },
  },

  mounted() {
    this.getStates()
    if (this.farmId) {
      this.getFarm()
    } else {
      window.$('#modalCreateEditFarm').modal()
    }
  },
}
</script>
