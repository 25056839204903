<template>
  <div class="content">
    <create-edit-client-modal
      v-if="showCreateEditClientModal"
      :clientId="clientIdSelected"
      @closeModal="closeCreateEditClientModal"
    />
    <inceres-login-modal
      v-if="showInceresLoginModal"
      @closeModal="closeInceresLoginModal"
    />
    <loading-data v-if="loadData" />
    <div v-else class="card">
      <div class="card-header bg-transparent header-elements-inline">
        <h5 class="card-title">Clientes</h5>
        <div v-if="getModuleUserByCode('1.01.002')" class="header-elements">
          <button
            type="button"
            class="btn btn-warning mr-2"
            title="Sincronizar Clientes com Inceres"
            @click="syncClients()"
            :disabled="disableSyncButton"
          >
            <span v-if="disableSyncButton">
              <i class="icon-spinner2 spinner" />
            </span>
            <span v-else>Sincronizar Clientes com Inceres</span>
          </button>
          <button
            type="button"
            class="btn btn-primary"
            title="Novo Cliente"
            @click="createEditClient()"
          >
            Novo Cliente
          </button>
        </div>
      </div>
      <list-clients :clients="clients" @editClient="createEditClient" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LoadingData from '@/components/commons/LoadingData.vue'
import CreateEditClientModal from '@/components/backoffice/clients/CreateEditClientModal.vue'
import InceresLoginModal from '@/components/backoffice/clients/InceresLoginModal.vue'
import ListClients from '@/components/backoffice/clients/ListClients.vue'

export default {
  name: 'Clients',

  components: {
    LoadingData,
    CreateEditClientModal,
    ListClients,
    InceresLoginModal,
  },

  data() {
    return {
      loadData: true,
      showCreateEditClientModal: false,
      showInceresLoginModal: false,
      clients: [],
      clientIdSelected: null,
      disableSyncButton: false,
    }
  },

  computed: {
    ...mapGetters(['getModuleUserByCode']),
  },

  methods: {
    async getClients() {
      try {
        this.clients = await this.$store.dispatch('getClients')
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    async syncClients() {
      try {
        this.disableSyncButton = true
        const result = await this.$store.dispatch('syncClients')

        if (result.status) {
          this.$root.$children[0].$refs.notification.success(result.message)
          this.getClients()
        } else {
          this.showInceresLoginModal = true
        }
        this.disableSyncButton = false
      } catch (error) {
        this.disableSyncButton = false
        if (error && error.message) {
          if (error.message === 'Not Authorized') {
            this.showInceresLoginModal = true
          } else {
            this.$root.$children[0].$refs.notification.warning(error.message)
          }
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    createEditClient(clientId) {
      this.clientIdSelected = clientId || null
      this.showCreateEditClientModal = true
    },

    closeCreateEditClientModal(loadListClients) {
      if (loadListClients) {
        this.loadData = true
        this.getClients()
      }
      this.showCreateEditClientModal = false
    },

    closeInceresLoginModal() {
      this.showInceresLoginModal = false
    },
  },

  mounted() {
    this.getClients()
  },
}
</script>
