<template>
  <div>
    <create-edit-farm-modal
      v-if="showCreateEditFarmModal"
      :clientId="clientId"
      :farmId="farmIdSelected"
      @closeModal="closeCreateEditFarmModal"
    />
    <inceres-login-modal
      v-if="showInceresLoginModal"
      @closeModal="closeInceresLoginModal"
    />
    <div v-if="!loadData" class="card">
      <div class="card-header bg-transparent header-elements-inline">
        <h5 class="card-title">Fazendas</h5>
        <div v-if="getModuleUserByCode('1.01.002')" class="header-elements">
          <button
            v-if="clientSelected.inceresId"
            type="button"
            class="btn btn-warning mr-2"
            title="Sincronizar Clientes com Inceres"
            @click="syncFarms()"
            :disabled="disableSyncButton"
          >
            <span v-if="disableSyncButton">
              <i class="icon-spinner2 spinner" />
            </span>
            <span v-else>Sincronizar Fazendas com Inceres</span>
          </button>
          <button
            type="button"
            class="btn btn-primary"
            title="Nova Fazenda"
            @click="createEditFarm()"
          >
            Nova Fazenda
          </button>
        </div>
      </div>
      <list-farms :farms="farms" @editFarm="createEditFarm" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ListFarms from '@/components/backoffice/farms/ListFarms.vue'
import InceresLoginModal from '@/components/backoffice/clients/InceresLoginModal.vue'
import CreateEditFarmModal from '@/components/backoffice/farms/CreateEditFarmModal.vue'

export default {
  name: 'Farms',

  components: {
    CreateEditFarmModal,
    ListFarms,
    InceresLoginModal,
  },

  props: {
    clientId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      loadData: true,
      showCreateEditFarmModal: false,
      showInceresLoginModal: false,
      farms: [],
      farmIdSelected: null,
      disableSyncButton: false,
    }
  },

  computed: {
    ...mapGetters(['getModuleUserByCode', 'clientSelected']),
  },

  methods: {
    async getFarms() {
      try {
        this.farms = await this.$store.dispatch('getFarms', {
          clientId: this.clientId,
        })
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    async syncFarms() {
      try {
        this.disableSyncButton = true
        const result = await this.$store.dispatch(
          'syncFarms',
          this.clientSelected.inceresId,
        )

        if (result.status) {
          this.$root.$children[0].$refs.notification.success(result.message)
          this.getFarms()
        } else {
          this.showInceresLoginModal = true
        }
        this.disableSyncButton = false
      } catch (error) {
        this.disableSyncButton = false
        if (error && error.message) {
          if (error.message === 'Not Authorized') {
            this.showInceresLoginModal = true
          } else {
            this.$root.$children[0].$refs.notification.warning(error.message)
          }
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    createEditFarm(farmId) {
      this.farmIdSelected = farmId || null
      this.showCreateEditFarmModal = true
    },

    closeCreateEditFarmModal(loadListFarms) {
      if (loadListFarms) {
        this.loadData = true
        this.getFarms()
      }
      this.showCreateEditFarmModal = false
    },

    closeInceresLoginModal() {
      this.showInceresLoginModal = false
    },
  },

  mounted() {
    this.getFarms()
  },
}
</script>
