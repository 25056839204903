<template>
  <div class="content">
    <details-client :clientId="clientId" />
    <farms :clientId="clientId" />
  </div>
</template>

<script>
import DetailsClient from '@/components/backoffice/clients/DetailsClient.vue'
import Farms from '@/components/backoffice/farms/Farms.vue'

export default {
  name: 'ClientDetails',

  components: {
    DetailsClient,
    Farms,
  },

  data() {
    return {
      clientId: this.$route.params.clientId,
    }
  },
}
</script>
