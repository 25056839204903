<template>
  <div class="card">
    <div class="card-header bg-transparent header-elements-inline">
      <h5 class="card-title">Detalhes da Fazenda</h5>
      <div class="header-elements">
        <router-link
          :to="`/backoffice/clients/${clientId}`"
          class="btn btn-outline-dark"
          title="Listagem de Fazendas"
        >
          Listagem de Fazendas
        </router-link>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-4 col-sm-6">
          <p><strong>Nome:</strong> {{ farm.name }}</p>
        </div>
        <div class="col-md-4 col-sm-6">
          <p><strong>Cidade/Estado:</strong> {{ farm.city }}/{{ farm.uf }}</p>
        </div>
        <div class="col-md-4 col-sm-6">
          <p>
            <strong>Status: </strong>
            <span v-html="statusTranslate(farm.status)" />
          </p>
        </div>
        <div class="col-md-4 col-sm-6">
          <p><strong>Latitude:</strong> {{ farm.latitude }}</p>
        </div>
        <div class="col-md-4 col-sm-6">
          <p><strong>Longitude:</strong> {{ farm.longitude }}</p>
        </div>
        <div class="col-md-4 col-sm-6">
          <p>
            <strong>Total (talhões ativo):</strong>
            {{ farm.totalAreaAtiva }}
          </p>
        </div>
        <div class="col-md-4 col-sm-6">
          <p>
            <strong>Criado por:</strong>
            {{ createdUpdatedUser(farm.createdBy, farm.createdAt) }}
          </p>
        </div>
        <div class="col-md-4 col-sm-6">
          <p>
            <strong>Atualizado por:</strong>
            {{ createdUpdatedUser(farm.updatedBy, farm.updatedAt) }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { createdUpdatedUser, statusTranslate } from '@/utils/strings'

export default {
  name: 'DetailsFarm',

  props: {
    clientId: {
      type: String,
      required: true,
    },
    farmId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      farm: {},
      createdUpdatedUser,
      statusTranslate,
    }
  },

  computed: {
    ...mapGetters(['reloadDetailsFarm']),
  },

  watch: {
    reloadDetailsFarm: {
      handler(newVal, oldVal) {
        if (newVal === true) {
          this.getFarm()
          this.$store.dispatch('setReloadDetailsFarm', false)
        }
      },
      immediate: true,
    },
  },

  methods: {
    async getFarm() {
      try {
        this.farm = await this.$store.dispatch('getFarm', {
          clientId: this.clientId,
          farmId: this.farmId,
        })
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },

  mounted() {
    this.getFarm()
  },
}
</script>
