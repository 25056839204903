<template>
  <table class="table" :id="dataTable.id">
    <thead>
      <tr>
        <th>Nome</th>
        <th>CNPJ/CPF</th>
        <th>Telefone</th>
        <th>E-mail</th>
        <th>Cidade/Estado</th>
        <th class="text-center">Status</th>
        <th class="text-center">Ações</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="client in clients" :key="client.id">
        <td>{{ client.name }}</td>
        <td>{{ client.cpfcnpj }}</td>
        <td>{{ client.phone }}</td>
        <td>{{ client.email }}</td>
        <td>{{ client.city }}/{{ client.uf }}</td>
        <td class="text-center" v-html="statusTranslate(client.status)" />
        <td class="text-center">
          <button
            v-if="getModuleUserByCode('1.01.003')"
            type="button"
            class="btn btn-outline-warning btn-icon rounded-pill"
            title="Editar"
            @click="$emit('editClient', client.id)"
          >
            <i class="icon-pencil" />
          </button>
          <router-link
            :to="`clients/${client.id}`"
            class="btn btn-outline-primary btn-icon rounded-pill ml-2"
            title="Detalhes"
          >
            <i class="far fa-file-alt" />
          </router-link>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import 'datatables.net-dt/js/dataTables.dataTables'
import '@/utils/basicDataTableExtend'
import { mapGetters } from 'vuex'
import { statusTranslate } from '@/utils/strings'

export default {
  name: 'ListClients',

  props: {
    clients: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      dataTable: {
        id: 'dataTableListClients',
        columnDefs: [
          {
            orderable: false,
            width: 130,
            targets: [6],
          },
        ],
      },
      statusTranslate,
    }
  },

  computed: {
    ...mapGetters(['getModuleUserByCode']),
  },

  methods: {
    createDataTable() {
      window.$(`#${this.dataTable.id}`).DataTable({
        columnDefs: this.dataTable.columnDefs,
      })
    },
  },

  mounted() {
    this.createDataTable()
  },
}
</script>
