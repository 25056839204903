<template>
  <div class="content">
    <details-client :clientId="clientId" />
    <details-farm :clientId="clientId" :farmId="farmId" />
    <areas :clientId="clientId" :farmId="farmId" />
  </div>
</template>

<script>
import DetailsClient from '@/components/backoffice/clients/DetailsClient.vue'
import DetailsFarm from '@/components/backoffice/farms/DetailsFarm.vue'
import Areas from '@/components/backoffice/areas/Areas.vue'

export default {
  name: 'FarmDetails',

  components: {
    DetailsClient,
    DetailsFarm,
    Areas,
  },

  data() {
    return {
      clientId: this.$route.params.clientId,
      farmId: this.$route.params.farmId,
    }
  },
}
</script>
