<template>
  <div
    id="modalCreateEditArea"
    class="modal fade"
    tabindex="-1"
    data-keyboard="false"
    data-backdrop="static"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ textModal }}
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            title="Fechar"
            @click="$emit('closeModal', false)"
          >
            &times;
          </button>
        </div>
        <form @submit.prevent="save">
          <div class="modal-body">
            <div class="form-row">
              <div class="form-group col-sm-12">
                <label>
                  Nome do Talhão: <span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': $v.area.name.$error }"
                  placeholder="Nome do Talhão"
                  v-model="$v.area.name.$model"
                />
                <span v-if="!$v.area.name.required" class="invalid-feedback">
                  O campo é obrigatório
                </span>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-12">
                <label>Hectares: <span class="text-danger">*</span></label>
                <input
                  type="number"
                  class="form-control"
                  step=".00001"
                  :class="{ 'is-invalid': $v.area.hectare.$error }"
                  placeholder="Hectares"
                  v-model.number="$v.area.hectare.$model"
                />
                <span v-if="!$v.area.hectare.required" class="invalid-feedback">
                  O campo é obrigatório
                </span>
              </div>
            </div>
            <div v-if="getModuleUserByCode('1.01.004')" class="form-row">
              <div class="form-group col-sm-6">
                <label> Número de Grids:</label>
                <input
                  type="number"
                  class="form-control"
                  min="1"
                  step="1"
                  placeholder="Número de Grids"
                  v-model.number="area.numberGrid"
                />
              </div>
              <div class="form-group col-sm-6">
                <label>Preço (R$):</label>
                <input
                  type="number"
                  class="form-control"
                  min="0"
                  step=".01"
                  placeholder="Preço"
                  v-model.number="area.price"
                />
              </div>
            </div>
            <div v-if="areaId" class="form-row">
              <div class="form-group col-sm-6">
                <p>Status: <span class="text-danger">*</span></p>
                <div class="form-check form-check-inline">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="statusInativo"
                    value="0"
                    v-model="area.status"
                  />
                  <label class="form-check-label" for="statusInativo">
                    Inativo
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="statusAtivo"
                    value="1"
                    v-model="area.status"
                  />
                  <label class="form-check-label" for="statusAtivo">
                    Ativo
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-link"
              data-dismiss="modal"
              title="Cancelar"
              @click="$emit('closeModal', false)"
            >
              Cancelar
            </button>
            <button
              type="submit"
              class="btn btn-primary"
              :title="textModal"
              :disabled="$v.$invalid || disableButton"
            >
              <span v-if="disableButton">
                <i class="icon-spinner2 spinner" />
              </span>
              <span v-else>
                {{ textModal }}
              </span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'

export default {
  name: 'CreateEditAreaModal',

  props: {
    clientId: {
      type: String,
      required: true,
    },
    farmId: {
      type: String,
      required: true,
    },
    areaId: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      area: {
        clientId: this.clientId,
        farmId: this.farmId,
        name: '',
        hectare: null,
        numberGrid: null,
        price: null,
        status: '',
      },
      disableButton: false,
      loadDataEditing: false,
    }
  },

  computed: {
    ...mapGetters(['getModuleUserByCode']),
    textModal() {
      return this.areaId ? 'Atualizar' : 'Cadastrar'
    },
  },

  methods: {
    async save() {
      if (!this.$v.$invalid) {
        this.disableButton = true
        try {
          let response = null
          if (this.areaId) {
            response = await this.$store.dispatch('updateArea', {
              clientId: this.clientId,
              farmId: this.farmId,
              areaId: this.areaId,
              area: this.area,
            })
          } else {
            response = await this.$store.dispatch('saveArea', {
              clientId: this.clientId,
              farmId: this.farmId,
              area: this.area,
            })
          }
          this.disableButton = false
          window.$('#modalCreateEditArea').modal('hide')
          this.$emit('closeModal', true)
          this.$root.$children[0].$refs.notification.success(response.message)
        } catch (error) {
          this.disableButton = false
          if (error && error.message) {
            this.$root.$children[0].$refs.notification.warning(error.message)
          } else {
            this.$root.$children[0].$refs.notification.danger(
              'Não foi possível se conectar ao servidor.',
            )
          }
        }
      }
    },

    async getArea() {
      try {
        this.loadDataEditing = true
        const response = await this.$store.dispatch('getArea', {
          clientId: this.clientId,
          farmId: this.farmId,
          areaId: this.areaId,
        })
        this.area = { ...response, hectare: parseFloat(response.hectare) }
        window.$('#modalCreateEditArea').modal()
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
        window.$('#modalCreateEditArea').modal('hide')
        this.$emit('closeModal', false)
      }
    },
  },

  validations: {
    area: {
      name: {
        required,
      },
      hectare: {
        required,
      },
    },
  },

  mounted() {
    if (this.areaId) {
      this.getArea()
    } else {
      window.$('#modalCreateEditArea').modal()
    }
  },
}
</script>
