<template>
  <div>
    <create-edit-area-modal
      v-if="showCreateEditAreaModal"
      :clientId="clientId"
      :farmId="farmId"
      :areaId="areaIdSelected"
      @closeModal="closeCreateEditAreaModal"
    />
    <inceres-login-modal
      v-if="showInceresLoginModal"
      @closeModal="closeInceresLoginModal"
    />
    <div v-if="!loadData" class="card">
      <div class="card-header bg-transparent header-elements-inline">
        <h5 class="card-title">Talhões</h5>
        <div v-if="getModuleUserByCode('1.01.002')" class="header-elements">
          <button
            v-if="clientSelected.inceresId && farmSelected.inceresFarmId"
            type="button"
            class="btn btn-warning mr-2"
            title="Sincronizar Talhões com Inceres"
            @click="syncAreas()"
            :disabled="disableSyncButton"
          >
            <span v-if="disableSyncButton">
              <i class="icon-spinner2 spinner" />
            </span>
            <span v-else>Sincronizar Talhões com Inceres</span>
          </button>
          <button
            type="button"
            class="btn btn-primary"
            title="Novo Talhão"
            @click="createEditArea()"
          >
            Novo Talhão
          </button>
        </div>
      </div>
      <list-areas :areas="areas" @editArea="createEditArea" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import InceresLoginModal from '@/components/backoffice/clients/InceresLoginModal.vue'
import CreateEditAreaModal from '@/components/backoffice/areas/CreateEditAreaModal.vue'
import ListAreas from '@/components/backoffice/areas/ListAreas.vue'

export default {
  name: 'Areas',

  components: {
    CreateEditAreaModal,
    ListAreas,
    InceresLoginModal,
  },

  props: {
    clientId: {
      type: String,
      required: true,
    },
    farmId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      loadData: true,
      showCreateEditAreaModal: false,
      showInceresLoginModal: false,
      areas: [],
      areaIdSelected: null,
      disableSyncButton: false,
    }
  },

  computed: {
    ...mapGetters(['getModuleUserByCode', 'clientSelected', 'farmSelected']),
  },

  methods: {
    async getAreas() {
      try {
        this.areas = await this.$store.dispatch('getAreas', {
          clientId: this.clientId,
          farmId: this.farmId,
        })
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    async syncAreas() {
      try {
        this.disableSyncButton = true
        const result = await this.$store.dispatch('syncAreas', {
          inceresClientId: this.clientSelected.inceresId,
          inceresFarmId: this.farmSelected.inceresFarmId,
        })

        if (result.status) {
          this.$root.$children[0].$refs.notification.success(result.message)
          this.$store.dispatch('setReloadDetailsFarm', true)
          this.getAreas()
        } else {
          this.showInceresLoginModal = true
        }
        this.disableSyncButton = false
      } catch (error) {
        this.disableSyncButton = false
        if (error && error.message) {
          if (error.message === 'Not Authorized') {
            this.showInceresLoginModal = true
          } else {
            this.$root.$children[0].$refs.notification.warning(error.message)
          }
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    createEditArea(areaId) {
      this.areaIdSelected = areaId || null
      this.showCreateEditAreaModal = true
    },

    closeCreateEditAreaModal(loadListAreas) {
      if (loadListAreas) {
        this.loadData = true
        this.$store.dispatch('setReloadDetailsFarm', true)
        this.getAreas()
      }
      this.showCreateEditAreaModal = false
    },

    closeInceresLoginModal() {
      this.showInceresLoginModal = false
    },
  },

  mounted() {
    this.getAreas()
  },
}
</script>
