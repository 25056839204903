<template>
  <div>
    <loading-data v-if="loadData" />
    <div v-else class="card">
      <div class="card-header bg-transparent header-elements-inline">
        <h5 class="card-title">Detalhes do Cliente</h5>
        <div class="header-elements">
          <router-link
            to="/backoffice/clients"
            class="btn btn-outline-dark"
            title="Listagem de Clientes"
          >
            Listagem de Clientes
          </router-link>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-4 col-sm-6">
            <p><strong>Nome:</strong> {{ client.name }}</p>
          </div>
          <div class="col-md-4 col-sm-6">
            <p><strong>CPF/CNPJ:</strong> {{ client.cpfcnpj }}</p>
          </div>
          <div class="col-md-4 col-sm-6">
            <p><strong>Telefone:</strong> {{ client.phone }}</p>
          </div>
          <div class="col-md-4 col-sm-6">
            <p><strong>E-mail:</strong> {{ client.email }}</p>
          </div>
          <div class="col-md-4 col-sm-6">
            <p>
              <strong>Cidade/Estado:</strong> {{ client.city }}/{{ client.uf }}
            </p>
          </div>
          <div class="col-md-4 col-sm-6">
            <p>
              <strong>Status: </strong>
              <span v-html="statusTranslate(client.status)" />
            </p>
          </div>
          <div class="col-md-4 col-sm-6">
            <p>
              <strong>Criado por:</strong>
              {{ createdUpdatedUser(client.createdBy, client.createdAt) }}
            </p>
          </div>
          <div class="col-md-4 col-sm-6">
            <p>
              <strong>Atualizado por:</strong>
              {{ createdUpdatedUser(client.updatedBy, client.updatedAt) }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createdUpdatedUser, statusTranslate } from '@/utils/strings'
import LoadingData from '@/components/commons/LoadingData.vue'

export default {
  name: 'DetailsClient',

  components: {
    LoadingData,
  },

  props: {
    clientId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      loadData: true,
      client: {},
      createdUpdatedUser,
      statusTranslate,
    }
  },

  methods: {
    async getClient() {
      try {
        this.client = await this.$store.dispatch('getClient', this.clientId)
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },

  mounted() {
    this.getClient()
  },
}
</script>
